setupCalloutAnimations();

function setupCalloutAnimations() {
    var initial = 'polygon(0 0, 0 0, 0 100%, 0% 100%)';
    var show = 'polygon(0 0, 100% 0, 100% 100%, 0 100%)';
    var hide = 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)';
    var reveals = $( ".section--callouts .reveal" );
    var headings = $(".section--callouts .heading");

    $.each( reveals, function( index, object ) {
        var revealer = $( object ).find( '.revealer' );
        var image = $( object ).find( '.image' );

        $( revealer ).waypoint( function() {
            var revealImage = gsap.timeline();

            revealImage.to(revealer, {
                clipPath: initial,
                duration: .5,
                ease: "circ.inOut"
            })

            revealImage.to(revealer, {
                clipPath: show,
                duration: .5,
                ease: "circ.inOut",
                onComplete: () => {
                    $( image ).css( 'visibility', 'visible' );
                }
            })

            revealImage.to(revealer, {
                clipPath: hide,
                duration: .5,
                ease: "circ.inOut"
            })

            revealImage.play();
            this.destroy();

        }, {
            offset: '90%'
        });
    } );

    if($(window).width() > 1088){
        $.each( headings, function( index, object ) {
            const anim = gsap.timeline({
                scrollTrigger: {
                    trigger: object,
                    start: "center 100%",
                    scrub: 1,
                },
            });

            anim.fromTo(object, 
                {
                    x: "-3%"
                },
                {
                    x: "-13%"
                }
            )
        });
    }
}