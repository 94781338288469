// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            var scrollOffset = 50
            var scrollPoint = $(target).offset().top - scrollOffset;

            // Remove height of header if its sticky (happens on mobile)
            if($('.main-header').css('position') === 'fixed'){
              scrollPoint -= $('.main-header').height();
            }

            gsap.to(window, {
                scrollTo: {
                    y: scrollPoint
                }
            });
        }
    }
  });