var mainMobileMenu = mobileMenu(); 

$('[data-menu-toggle]').on('click', function(){
    mainMobileMenu.toggle();
});

$('.main-header a').on('click', function(){
    if($('.main-header').hasClass('is-active')){
        mainMobileMenu.close();
    }
})

function mobileMenu(){
    let menuIsOpen = false;
    const mainMenu = $('.main-header');
    const mainToggle = $('[data-menu-toggle]');

    function open(){
        mainMenu.addClass('is-active');
        mainToggle.addClass('is-active');
        $('body').addClass('no-scroll');
        menuIsOpen = true;
        mainToggle.attr('aria-expanded', menuIsOpen);
    }

    function close(){
        mainMenu.removeClass('is-active');
        mainToggle.removeClass('is-active');
        $('body').removeClass('no-scroll');
        menuIsOpen = false;
        mainToggle.attr('aria-expanded', menuIsOpen);
    }

    function toggle(){
        if(menuIsOpen){
            close();
        } else {
            open();
        }
    }

    // -------------------------------------

    let publicAPI = {
        open,
        close,
        toggle,
    }

    return publicAPI;
}