/**
 * Add loading class to Gravity Form buttons when clicked
 */
var handleFormButton = function() {
    $(this).addClass('is-loading');
}

/**
 * Custom Success Animation
 */
$(document).on('gform_confirmation_loaded', function(event, formId){
    if(formId == 1){
        var form = $(`[data-form-id="${formId}"]`); 
        var formContent = $(form).parents('.section--form').find('.content');

        // Hide the form heading and body text
        formContent.hide();

        // Confirmation Text Animation
        gsap.fromTo('.gform_confirmation_wrapper .text',
            {
                opacity: 0,
                y: 10,
            },
            {
                opacity: 1,
                y: 0,
                duration: 0.5,
                ease: "circ.inOut"
            }
        )

        // Smiley Animation
        gsap.fromTo('.gform_confirmation_wrapper svg .smiley',
            {
                opacity: 0,
                y: 10,
            },
            {
                opacity: 1,
                y: 0,
                duration: 0.5,
                ease: "circ.inOut"
            }
        )

        // Plus Animation
        gsap.fromTo('.gform_confirmation_wrapper svg .plus',
            {
                transformOrigin: 'center center',
                opacity: 0,
                scale: 0.2,
                y: 15,
            },
            {
                stagger: 0.1,
                opacity: 1,
                scale: 1,
                duration: 1,
                y: 0,
                ease: "circ.inOut"
            }
        )
    }
});
