let slideRight = true;
let totalDistance = $('.marquee-ticker .wrapper').width() / 2;

document.querySelectorAll('.marquee-ticker .wrapper').forEach(ticker => {
    let slideDirection = $(ticker).data('slide-direction');
    
    $(ticker).append($(ticker.querySelectorAll("li")).clone());

    gsap.to(ticker, { 
        duration: 250,
        x: slideDirection == 'right' ? totalDistance : -totalDistance,
        ease: "none",
        repeat: -1, 
        overwrite: true
    });
});